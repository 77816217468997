import { Enums } from '../../_shared/enums/enums';
import { environment } from '../../../environments/environment';
import { IFilterConfig } from '../../_shared/models/models-index';
import { Filter, FilterConfigReport, FilterType } from '../../_shared/filter/filter.model';
import * as constants from '../../_shared/constants/constants';
export class FilterConfig implements IFilterConfig {
  NUMBER_OF_MONTHS_TO_SHOW_DEFAULT = environment.numberOfMonthsToShowDefault;
  NUMBER_OF_QUARTERS_TO_SHOW_DEFAULT = environment.numberOfQuartersToShowDefault;
  dateModeOptions = [
    Enums.dateModes.currentMonth,
    Enums.dateModes.lastMonth,
    Enums.dateModes.lastThirtyDays,
    Enums.dateModes.previousThreeMonths,
    Enums.dateModes.previousTwelveMonths
  ]
  showEntireOrgHierarchyForAllUsers = false;
  includeInactiveDealersInMetrics = true;
  filterTypeLookup: { [id: string]: FilterType } = {
    'org': { displayName: 'Hierarchy', name: 'org' },
    'vehicle': { displayName: 'Vehicle', name: 'vehicle' },
    'chat-provider': { displayName: 'Provider', name: 'chat-provider' },
    'device-type': { displayName: 'Device Type', name: 'device-type' },
    'lead-type': { displayName: 'Lead Type', name: 'lead-type' },
    'sale-type': { displayName: 'Sale Type', name: 'sale-type' },
    'source-type': { displayName: 'Source Type', name: 'source-type' },
    'vehicle-make': { displayName: 'Make', name: 'vehicle-make' },
    'vehicle-model': { displayName: 'Model', name: 'vehicle-model' },
    'website-provider': { displayName: 'Provider', name: 'website-provider' },
  };

  filterReportConfigs: { [id: string]: FilterConfigReport } = {
    // Leads
    'LeadsOverview': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.leadsOverview,
      filters: ['org'],
      defaults: ['org'],
      section: 'leads',
      orgTypes: [1, 4]
    },
    'LeadSummary': {
      dateMode: Enums.dateModes.currentMonth,
      quarterDateModeEnabled: true,
      reportName: constants.reportNames.leadSummary,
      filters: [
        'org', 'lead-type', 'source-type'
      ],
      defaults: [
        'org', 'lead-type', 'source-type'
      ],
      section: 'leads',
      orgTypes: [1, 4]
    },
    'LeadSourcePerformance': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.leadSourcePerformance,
      filters: [
        'org', 'lead-type', 'source-type', 'vehicle-model'
      ],
      defaults: [
        'org', 'lead-type', 'source-type'
      ],
      section: 'leads',
      orgTypes: [1, 4]
    },
    'LeadTransactions': {
      dateMode: Enums.dateModes.currentMonth,
      quarterDateModeEnabled: true,
      reportName: constants.reportNames.leadTransactions,
      filters: [
        'org', 'lead-type', 'source-type'
      ],
      defaults: [
        'org', 'lead-type', 'source-type'
      ],
      section: 'leads',
      orgTypes: [1, 4]
    },
    'ProcessPerformance': {
      dateMode: Enums.dateModes.currentMonth,
      quarterDateModeEnabled: true,
      reportName: constants.reportNames.processPerformance,
      filters: [
        'org', 'lead-type', 'source-type'
      ],
      defaults: [
        'org', 'lead-type', 'source-type'
      ],
      section: 'leads',
      orgTypes: [1, 4]
    },
    // Sales
    'SalesOverview': {
      dateMode: Enums.dateModes.currentMonth,
      quarterDateModeEnabled: true,
      restrictDates: true,
      reportName: constants.reportNames.salesOverview,
      filters: ['org'],
      defaults: ['org'],
      section: 'sales',
      orgTypes: [1, 4],
      defaultMonthDateMode: 'sales'
    },
    'SalesSummary': {
      dateMode: Enums.dateModes.currentMonth,
      quarterDateModeEnabled: true,
      restrictDates: false,
      reportName: constants.reportNames.salesSummary,
      filters: [
        'org', 'sale-type', 'lead-type', 'source-type'
      ],
      defaults: [
        'org', 'sale-type', 'lead-type', 'source-type'
      ],
      section: 'sales',
      orgTypes: [1, 4],
      defaultMonthDateMode: 'sales'
    },
    'SalesSourceSummary': {
      dateMode: Enums.dateModes.currentMonth,
      quarterDateModeEnabled: true,
      restrictDates: false,
      reportName: constants.reportNames.salesSourceSummary,
      filters: [
        'org', 'lead-type'
      ],
      defaults: [
        'org', 'lead-type'
      ],
      section: 'sales',
      orgTypes: [1, 4],
      defaultMonthDateMode: 'sales'
    },
    'SalesVehicleSummary': {
      dateMode: Enums.dateModes.currentMonth,
      quarterDateModeEnabled: true,
      restrictDates: true,
      reportName: constants.reportNames.salesVehicleSummary,
      filters: [
        'org', 'lead-type', 'sale-type', 'source-type'
      ],
      defaults: [
        'org', 'lead-type', 'sale-type', 'source-type'
      ],
      section: 'sales',
      orgTypes: [1, 4],
      defaultMonthDateMode: 'sales'
    },
    // Chat
    'ChatOverview': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.chatOverview,
      filters: ['org', 'device-type', 'chat-provider'],
      defaults: ['org'],
      section: 'provider-tools',
      orgTypes: [1, 4]
    },
    'LeadTransactionsOverview': {
      dateMode: Enums.dateModes.currentMonth,
      quarterDateModeEnabled: true,
      reportName: constants.reportNames.leadTransactionsOverview,
      filters: [
        'org', 'lead-type', 'source-type'
      ],
      defaults: [
        'org', 'lead-type', 'source-type'
      ],
      section: 'leads',
      orgTypes: [1, 4]
    },
  };

  FILTER_CONFIG: { [id: string]: Filter} = {
    'org': {
      type: 'org',
      selected: [],
      removable: false,
      lockable: true,
      locked: true
    },
    'vehicle': {
      type: 'vehicle',
      selected: [],
      removable: false,
      lockable: true,
      locked: true
    },
    'chat-provider': {
      type: 'chat-provider',
      available: [],
      selected: [],
      removable: true,
      lockable: true,
      locked: false
    },
    'device-type': {
      type: 'device-type',
      selected: [
        { value: 1, display: 'Desktop' },
        { value: 2, display: 'Tablet' },
        { value: 3, display: 'Phone' }
      ],
      removable: false,
      lockable: true,
      locked: false
    },
    'lead-type': {
      type: 'lead-type',
      selected: [{ value: 1, display: 'New Sales' }],
      removable: false,
      lockable: true,
      locked: false
    },
    'sale-type': {
      type: 'sale-type',
      selected: [
        { value: 1, display: 'New' }
      ],
      removable: false,
      lockable: true,
      locked: false
    },
    'source-type': {
      type: 'source-type',
      available: [2, 8, 1, 9, 6, 3, 7, 5, 10],
      selected: [
        { value: 2, display: '3rd Party' },
        { value: 8, display: 'Brand - NABR' },
        { value: 1, display: 'Brand - Other' },
        { value: 9, display: 'Chat' },
        { value: 6, display: 'Dealer Generated' },
        { value: 3, display: 'Dealer Website' },
        { value: 7, display: 'Digital Retail' },
        { value: 10, display: 'Digital Retail: In-Store' },
        { value: 5, display: 'Event' },
      ],
      removable: false,
      lockable: true,
      locked: false
    },
    'vehicle-make': {
      type: 'vehicle-make',
      selected: [],
      removable: true,
      lockable: true,
      locked: false
    },
    'vehicle-class': {
      type: 'vehicle-class',
      selected: [],
      removable: true,
      lockable: true,
      locked: false
    },
    'vehicle-model': {
      type: 'vehicle-model',
      selected: [],
      removable: true,
      lockable: true,
      locked: false
    },
    'website-provider': {
      type: 'website-provider',
      available: [1, 2, 3, 4],
      selected: [
        { value: 1, display: 'DealerFire' },
        { value: 2, display: 'Dealer Inspire' },
        { value: 3, display: 'DealerOn' },
        { value: 4, display: 'Dealer.com' },
      ],
      removable: false,
      lockable: true,
      locked: false
    },
  };
}
